
<template>
  <div class="wrap-content">
    <div class="header" :class="{ fixed: isFiexd == true }">
      <div class="return">
        <div class="return-icon" @click="handleBack">
          <Icon type="ios-arrow-back" />
        </div>
        <span>数据目录填报</span>
      </div>

      <div class="button">
        <div class="btn btn-simple" @click="modal1 = true">取消</div>
        <div class="btn btn-simple" @click="Staging">暂存草稿</div>
        <div class="btn btn-default" @click="submitData"  v-if="!isEdit">提交</div>
        <div class="btn btn-default" @click="updateData" v-if="isEdit">
          提交
          </div>
        <CommonHeader />
      </div>
    </div>
    <div class="main">
      <div class="main-content">
        <div class="info" id="basic">
          <div class="title margin-20">
            <img src="../../assets/img/基本信息-已填写.png" alt="" />
            <span>基本信息</span>
          </div>
          <div class="form">
            <Form
              ref="formBaiscValidate"
              :model="formBaisc"
              :rules="ruleBasic"
              label-position="top"
            >
              <FormItem label="数据名称" prop="dataName">
                <Input
                  v-model="formBaisc.dataName"
                  placeholder="请输入"
                  @on-focus="focus(0)"
                ></Input>
              </FormItem>
              <FormItem label="一级分类" prop="first">
                <Select
                  v-model="formBaisc.first"
                  @on-open-change="focus(1)"
                  @on-change="changeFirst"
                >
                  <Option
                    :value="item.value"
                    v-for="item in firstList"
                    :key="item.value"
                    >{{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="二三级分类" prop="dataType">
                <el-cascader
                  :options="dataTypeList"
                  :props="props2"
                  clearable
                  v-model="formBaisc.dataType"
                  placeholder="请输入"
                  @focus="focus(2)"
                />
              </FormItem>

              <FormItem label="数据描述" prop="description">
                <Input
                  v-model="formBaisc.description"
                  placeholder="请输入"
                  @on-focus="focus(3)"
                />
              </FormItem>
              <FormItem label="拟定等级" prop="level">
                <Select
                  v-model="formBaisc.level"
                  @on-open-change="focus(4)"
                  disabled
                >
                  <Option value="一般数据">一般数据</Option>
                </Select>
              </FormItem>

              <FormItem label="数据量（单位：GB）" prop="dataSize">
                <Input
                  v-model="formBaisc.dataSize"
                  placeholder="请输入"
                  type="number"
                  @on-focus="focus(5)"
                ></Input>
              </FormItem>
              <FormItem label="数据记录数" prop="dataCount">
                <Input
                  v-model="formBaisc.dataCount"
                  placeholder="请输入"
                  @on-focus="focus(6)"
                ></Input>
              </FormItem>
              <FormItem label="数据载体类型" prop="payload">
                <Select v-model="formBaisc.payload" @on-open-change="focus(7)">
                  <Option value="纸质载体">纸质载体</Option>
                  <Option value="光盘、硬盘等存储介质"
                    >光盘、硬盘等存储介质</Option
                  >
                  <Option value="信息系统">信息系统</Option>
                </Select>
              </FormItem>

              <FormItem label="信息系统名称">
                <Input
                  v-model="formBaisc.appName"
                  placeholder=""
                  @on-focus="focus(8)"
                ></Input>
              </FormItem>
              <FormItem label="所在数据中心" prop="dataCenter">
                <Input
                  v-model="formBaisc.dataCenter"
                  placeholder=""
                  @on-focus="focus(9)"
                ></Input>
              </FormItem>
            </Form>
          </div>
        </div>

        <div class="footer">
          <div class="btn btn-simple margin-right-10" @click="modal1 = true">
            取消
          </div>
          <div class="btn btn-simple margin-right-10" @click="Staging">
            暂存草稿
          </div>
          <div class="btn btn-default" @click="submitData" v-if="!isEdit">
            提交
          </div>
          <div class="btn btn-default" @click="updateData" v-if="isEdit">
            提交
          </div>
        </div>
      </div>
      <div class="point">
        <div class="point-content">
          <h2>{{ title }}</h2>
          <div class="tip">{{ tip }}</div>
          <p>{{ desc }}</p>
        </div>
      </div>
    </div>
    <BackTop :bottom="100"></BackTop>
    <Modal
      v-model="modal1"
      sticky
      scrollable
      class="custom-modal-footer-block"
      @on-cancel="modal1 = false"
    >
      <template #header>
        <p class="title">提醒</p>
        <div class="modal-close" @click="modal1 = false">
          关闭 <span>ESC</span>
        </div>
      </template>
      <div class="alarm-info">你确定要取消创建吗？</div>
      <template #footer>
        <div class="btn btn-default" @click="handleReset">确认</div>
      </template>
    </Modal>
  </div>
</template>
  <script setup>
import {
  reactive,
  ref,
  watch,
  onMounted,
  nextTick,
  onBeforeUnmount,
  inject,
} from "vue";
// import province from "@/utils/province";
import { Message, Modal } from "view-ui-plus";
import { useRouter, useRoute } from "vue-router";
import CommonHeader from "@/components/commonHeader.vue";
let router = useRouter();
let route = useRoute();
let id = ref(""); //暂存项目的id
const handleBack = () => {
  router.back();
};
let modal1 = ref(false);
let formBaiscValidate = ref(null);
//验证数据是否填写完整
let isCompleteBasic = ref(false);
let formBaisc = ref({
  dataName: "",
  dataType: [],
  description: "",
  level: "一般数据",
  dataSize: "",
  dataCount: "",
  payload: "",
  appName: "",
  dataCenter: "",
});

const validateDataType = (rule, value, callback) => {
  if (value == "") {
    callback(new Error("数据类别不能为空"));
  } else {
    let isAuth = false;
    value.forEach((item) => {
      if (item.length > 1) {
        isAuth = true;
      }
    });
    if (!isAuth) {
      callback(new Error("请选择三级数据"));
    }

    callback();
  }
};
let ruleBasic = {
  dataName: [
    {
      required: true,
      message: "数据名称不能为空",
      trigger: "blur",
    },
  ],
  dataType: [
    {
      required: true,
      validator: validateDataType,
      trigger: "change",
    },
  ],
  description: [
    {
      required: true,
      message: "数据描述不能为空",
      trigger: "blur",
    },
  ],

  level: [
    {
      required: true,
      message: "数据级别不能为空",
      trigger: "change",
    },
  ],
  dataSize: [
    {
      required: true,
      message: "数据数量（单位:GB）不能为空",
      trigger: "blur",
    },
  ],
  dataCount: [
    {
      required: true,
      message: "数据记录数不能为空",
      trigger: "blur",
    },
  ],
  payload: [
    {
      required: true,
      message: "数据载体类型不能为空",
      trigger: "change",
    },
  ],
  first: [
    {
      required: true,
      message: "一级分类不能为空",
      trigger: "blur",
    },
  ],
  dataCenter: [
    {
      required: true,
      message: "所在数据中心不能为空",
      trigger: "blur",
    },
  ],
};

//数据分类
let dataTypeList = ref([]);
let firstList = ref([]);
//获取分类数据
const getLevelData = () => {
  let url = `/content/index`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      firstList.value = res.data.data;
      nextTick(() => {
        getDataDetails();
      });
    }
  });
};
//选择一级分类
const changeFirst = (name) => {
  firstList.value.forEach((item) => {
    if (item.label == name) {
      dataTypeList.value = item.children;
    }
  });
  formBaisc.value.dataType = [];
};
const props2 = {
  multiple: true,
  checkStrictly: true,
};

let axios = inject("axios");
// 提交全部数据
const submitData = () => {
  formBaiscValidate.value.validate((valid) => {
    if (valid) {
      let url = `/content/generalSubmit`;
      let { dataType } = formBaisc.value;
      let params = {
        ...formBaisc.value,
        id: id.value,
      };
      let second = [],
        third = [];
      dataType.forEach((item) => {
        if (item.length == 1) {
          second.push(item[0]);
        }
        if (item.length == 2) {
          second.push(item[0]);
          third.push(item[1]);
        }
      });

      second = [...new Set(second)];
      third = [...new Set(third)];
      params.second = second;
      params.third = third;
      if (params.second.length == 0 || params.third.length == 0) {
        return Modal.error({
          title: "提醒",
          content: "请选择二级和三级数据",
          duration: 3,
        });
      }
      axios
        .post(url, params, {
          headers: {
            "content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Message.success("提交成功");
            router.push({
              name: "FillingList",
            });
          } else {
            Message.error({
              background: true,
              content: res.data.message,
            });
          }
        });
    } else {
      Message.error({
        background: true,
        content: "请检查所有项是否填写完整",
      });
    }
  });
};
//修改数据
const updateData = () => {
  formBaiscValidate.value.validate((valid) => {
    if (valid) {
      let url = `/content/update`;
      let { dataType } = formBaisc.value;
      let params = {
        ...formBaisc.value,
        id: id.value,
      };
      let second = [],
        third = [];
      dataType.forEach((item) => {
        if (item.length == 1) {
          second.push(item[0]);
        }
        if (item.length == 2) {
          second.push(item[0]);
          third.push(item[1]);
        }
      });

      second = [...new Set(second)];
      third = [...new Set(third)];
      params.second = second;
      params.third = third;
      if (params.second.length == 0 || params.third.length == 0) {
        return Message.error({
          background: true,
          content: "请选择二级和三级数据",
          duration: 3,
        });
      }
      axios
        .post(url, params, {
          headers: {
            "content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.code == 200) {
            Message.success("修改成功");
            router.push({
              name: "FillingList",
            });
          } else {
            Message.error({
              background: true,
              content: res.data.message,
            });
          }
        });
    } else {
      Message.error({
        background: true,
        content: "请检查所有项是否填写完整",
      });
    }
  });
};
//暂存草稿
const Staging = () => {
  let url = `/content/generalStore`;
  let { dataType } = formBaisc.value;
  let params = {
    ...formBaisc.value,
    id: id.value,
  };
  let second = [],
    third = [];
  dataType.forEach((item) => {
    if (item.length == 1) {
      second.push(item[0]);
    }
    if (item.length == 2) {
      second.push(item[0]);
      third.push(item[1]);
    }
  });

  second = [...new Set(second)];
  third = [...new Set(third)];
  params.second = second.length != 0 ? second : null;
  params.third = third.length != 0 ? third : null;
  axios
    .post(url, params, {
      headers: {
        "content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data.code == 200) {
        Message.success("暂存成功");
        router.push({
          name: "FillingList",
        });
      } else {
        Message.error({
          background: true,
          content: res.data.message,
        });
      }
    });
};

//取消重置
const handleReset = () => {
  router.push({
    name: "FillingList",
  });
};

let isFiexd = ref(false); //判断顶部导航位置是否要固定

let storage = ref({});
//查询暂存详情
const getDataDetails = () => {
  let url = `/content/generalInfo?dataId=${id.value}`;
  axios.get(url).then((res) => {
    if (res.data.code == 200) {
      let data = res.data.data;
      storage.value = data;
      id.value = data.id;
      formBaisc.value = {
        dataName: data.dataName,
        dataType: data.dataType,
        description: data.description,
        level: "一般数据",
        dataSize: data.dataSize != null ? data.dataSize.toString() : null,
        dataCount: data.dataCount != null ? data.dataCount.toString() : null,
        payload: data.payload,
        appName: data.appName,
        dataCenter: data.dataCenter,
        first: data.first,
      };
      firstList.value.forEach((item) => {
        if (item.label == formBaisc.value.first) {
          dataTypeList.value = item.children;
        }
      });
    }
  });
};

let desc = ref("必填，包括数据集、数据库、数据平台等");
let title = ref("数据名称");
let tip = ref("");
//输入框、下拉框聚焦
const focus = (val) => {
  switch (val) {
    case 0:
      title.value = "数据名称";
      tip.value = "";
      desc.value = `必填，包括数据集、数据库、数据平台等`;
      break;
    case 1:
      title.value = "一级分类";
      tip.value = "";
      desc.value = `必填`;
      break;
    case 2:
      title.value = "二三级分类";
      tip.value = "";
      desc.value = `必填，如有多个分类，可选择`;
      break;
    case 3:
      title.value = "数据描述";
      tip.value = "";
      desc.value = `必填，50字以上`;
      break;
    case 4:
      title.value = "拟定等级";
      tip.value = "";
      desc.value = `必填，一般`;
      break;
    case 5:
      title.value = "数据量";
      tip.value = "";
      desc.value = `必填，数值型，单位GB`;
      break;
    case 6:
      title.value = "数据记录数";
      tip.value = "";
      desc.value = `必填，单位：条，不涉及，填"/""`;
      break;
    case 7:
      title.value = "数据载体类型";
      tip.value = "";
      desc.value = `必填，单选`;
      break;
    case 8:
      title.value = "信息系统名称";
      tip.value = "";
      desc.value = `载体类型为信息系统的必填，填所在信息系统名称`;
      break;
    case 9:
      title.value = "所在数据中心";
      tip.value = "";
      desc.value = `必填，不在数据中心的填“无”`;
      break;
  }
};
//添加滚动事件
const addScrollFun = () => {
  let el = document.getElementById("wrap");
  el.addEventListener("scroll", listenScroll); // 监听页面滚动
};

const listenScroll = (e) => {
  // let scrollTop0 = document.getElementById("basic").offsetTop - 100;
  // let scrollTop1 = document.getElementById("part").offsetTop - 100;
  // let scrollTop2 = document.getElementById("dealWith").offsetTop - 100;
  // let scrollTop3 = document.getElementById("safe").offsetTop - 100;
  // let scrollTop4 = document.getElementById("remark").offsetTop - 100;
  // var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  var scrollTop = document.getElementById("wrap").scrollTop;
  // console.log(scrollTop);
  // if (scrollTop < scrollTop1) {
  //   navName.value = "basic";
  // }
  // if (scrollTop >= scrollTop1 && scrollTop < scrollTop2) {
  //   navName.value = "part";
  // }
  // if (scrollTop >= scrollTop2 && scrollTop < scrollTop3) {
  //   navName.value = "dealWith";
  // }
  // if (scrollTop >= scrollTop3 && scrollTop < scrollTop4) {
  //   navName.value = "safe";
  // }
  // if (scrollTop >= scrollTop4) {
  //   navName.value = "remark";
  // }
  if (scrollTop > 100) {
    isFiexd.value = true;
  } else {
    isFiexd.value = false;
  }
};
let isEdit = ref(false);
onMounted(() => {
  nextTick(() => {
    addScrollFun();
    getLevelData();
  });
  let query = route.query;
  //修改必定有数据集id，idEdit为非必填，不传为false，即为新建，true为更新
  if (query.id) {
    id.value = query.id;
    // getDataDetails();
  }
  if (query.isEdit) {
    isEdit.value = query.isEdit;
  } else {
    isEdit.value = false;
  }
});
onBeforeUnmount(() => {});
</script>
  
  <style lang="scss" scoped>
.wrap-content {
  padding: 10px 30px;
  background: #fff;
  height: 100vh;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  .return {
    display: flex;
    align-items: center;
    .return-icon {
      width: 24px;
      height: 24px;
      border: solid 2px #111111;
      border-radius: 6px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;
      cursor: pointer;
      i {
        font-size: 18px;
        color: #000;
      }
    }
    span {
      font-family: PingFangSC-Medium;
      font-size: 26px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 33px;
      color: #111111;
    }
    // span:last-child {
    //   color: #cccccc;
    // }
  }
  > ul {
    display: flex;
    li {
      font-size: 16px;
      margin-right: 20px;
      cursor: pointer;
      line-height: 70px;
      position: relative;
      a {
        font-family: PingFangSC-Regular;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 46px;
        letter-spacing: 0px;
        color: #555555;
      }
    }
    li.active {
      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 60px;
        height: 3px;
        background-color: #c22b22;
        transform: translate(-50%, -50%);
      }
      a {
        color: #c22b22;
        font-weight: bold;
      }
    }
  }
}
.fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  //  height: ;
  width: calc(100% - 200px);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  background: #fff;
  padding: 0 30px;
  > ul {
    li.active {
      &::before {
        bottom: -2px;
      }
    }
  }
}
.title {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  // font-weight: normal;
  font-stretch: normal;
  line-height: 33px;
  color: #111111;
  font-weight: bold;
  img {
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  span {
  }
}

.remark {
  label {
    font-size: 14px;
    display: block;
    min-width: 80px;
    margin-bottom: 10px;
  }
}
.footer {
  // border-top: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  margin-top: 30px;
}
.button {
  display: flex;
  align-items: center;
  > div {
    margin-left: 10px;
  }
  .btn {
    color: #fff;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
  }
  .icon {
    img {
      display: block;
      width: 20px;
      height: 18px;
    }
  }
}

//标题logo
.title-logo {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 8px;
}

.main {
  display: flex;
  position: relative;
  .main-content {
    width: calc(100% - 300px);
  }
  .point {
    width: 270px;
    min-height: 85vh;
    border-radius: 10px;
    border: solid 1px #ffe7ad;
    background-color: #fefdf6;
    // margin-left: 30px;
    margin-top: 25px;
    position: fixed;
    top: 88px;
    right: 30px;
    z-index: 10;
    .point-content {
      padding: 30px;
      h2 {
        text-align: center;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 33px;
        letter-spacing: 0px;
        color: #fd7e03;
        margin-bottom: 30px;
      }
      p {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #888888;
      }
      .point {
        font-family: PingFangSC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0px;
        color: #555555;
      }
    }
  }
}
.btn {
  padding: 0 25px;
}
</style>